import React,{ useContext } from 'react';
import Marquee from "react-fast-marquee";

import './Client.css'

import { ThemeContext } from '../../contexts/ThemeContext';
import { clientData } from '../../data/clientData'
import { clientImage } from '../../utils/clientImage'

function Client() {

    const { theme } = useContext(ThemeContext);

    const clientBoxStyle = {
        backgroundColor: theme.secondary,
        boxShadow: `0px 0px 30px ${theme.primary30}`
    }

    return (
        <div className="client" id='clients' style={{backgroundColor: theme.secondary}}>
            <div className="clientHeader">
                <h2 style={{color: theme.primary}}>Clients</h2>
            </div>
            <div className='clientSubHeader'>
                <h2 style={{color: theme.primary}}>THEY TRUSTED ME<br/>
                    AND THEY'RE NOT THE ONLY ONES
                </h2>
            </div>
            <div className="clientContainer">
                <div className="client--scroll">
                    <Marquee 
                        gradient={false} 
                        speed={80} 
                        pauseOnHover={true}
                        pauseOnClick={true} 
                        delay={0}
                        play={true} 
                        direction="left"
                    >
                        {clientData.map((client, id) => (
                            <div className="client--box" key={id} style={clientBoxStyle}>
                                <img src={clientImage(client)} alt={client} />
                                <h3 style={{color: theme.tertiary}}>
                                    {client}
                                </h3>
                            </div>
                        ))}
                    </Marquee>
                </div>
            </div>
        </div>
    )
}

export default Client
