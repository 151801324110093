import React, { useContext} from 'react';

import './Service.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { serviceData } from '../../data/serviceData'
import ServiceCard from './ServiceCard';

function Service() {

    const { theme } = useContext(ThemeContext);
    return (
        <>
            {serviceData.services.length > 0 && (
                <div className="service" id="services" style={{backgroundColor: theme.secondary}}>
                <div className="service-body">
                    <h1 style={{color: theme.primary}}>Services</h1>
                    <h4 style={{color:theme.tertiary}}>{serviceData.bio}</h4>
                </div>
                <div className="service-cards">
                    {serviceData.services.map(service => ( 
                        <ServiceCard 
                        key={service.id}
                        id={service.id}
                        title={service.title}
                        details={service.details}
                        date={service.date}
                        field={service.field}
                        image={service.image}/>
                    ))}
                </div>
            </div>
            )}
        </>
    )
}

export default Service
