import React, { useContext, useState } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { Fade } from "react-awesome-reveal";
import { IoHomeSharp } from 'react-icons/io5';
import { BsFillGearFill } from 'react-icons/bs';
import { MdPhone } from 'react-icons/md';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { FaPeopleGroup } from "react-icons/fa6";
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

import './Navbar.css';
import logo from '../../assets/png/technofsk-logo.png';
import { ThemeContext } from '../../contexts/ThemeContext';
import { FaUser } from 'react-icons/fa';

function Navbar() {
    const { theme, setHandleDrawer } = useContext(ThemeContext);

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
        setHandleDrawer();
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setHandleDrawer();
    };

    const NavMenuStyle = {
        fontSize: '2.5rem',
        color: theme.tertiary,
        cursor: 'pointer',
        transform: 'translateY(-10px)',
        transition: 'color 0.3s',
        '&:hover': {
            color: theme.primary,
        }
    };
    

    const ClosebtnIconStyle = {
        fontSize: '2rem',
        fontWeight: 'bold',
        cursor: 'pointer',
        color: theme.primary,
        position: 'absolute',
        right: 40,
        top: 30,
    };

    const DrawerItemStyle = {
        margin: '2rem auto',
        borderRadius: '78.8418px',
        background: theme.secondary,
        color: theme.primary,
        width: '85%',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        padding: '0 40px',
        boxSizing: 'border-box',
        border: '2px solid',
        borderColor: theme.primary,
        transition: 'background-color 0.2s, color 0.2s',
        '&:hover': {
            background: theme.primary,
            color: theme.secondary,
        },
    };

    const DrawerLinksStyle = {
        fontFamily: 'var(--primaryFont)',
        width: '50%',
        fontSize: '1.3rem',
        fontWeight: 500,
    };

    const drawerIconStyle = {
        fontSize: '1.6rem',
    }


    return (
        <div className='navbar'>
            <div className='navbar--container'>
                <div className='navbar--container-left' style={{ backgroundColor: theme.primary }}>
                    <h1 style={{ color: theme.secondary , zIndex: "99", marginTop:"2rem" }}>
                        <img src={logo} alt='TechnoFSK' style={{height:'100px'}} />
                    </h1>
                </div>
                
            
                <div className='navbar--container-right'style={{ backgroundColor: theme.secondary}}>
                    <div className="navbar-links_container">
                        <p><a href="/#homepage">Home</a></p>
                        <p><a href="/#about">About</a></p>
                        {/* <p><a href="/#clients">Clients</a></p> */}
                        <p><a href="/#services">Services</a></p>
                        <p><a href="/#contacts">Contacts</a></p>
                    </div>
                    <div className='navbar-menu'>
                        {open 
                            ? <RiCloseLine color="#fff" size={27} onClick={() => setOpen(false)} />
                            : <RiMenu3Line style={NavMenuStyle}  onClick={() => {setOpen(true); handleDrawerOpen();}} />}
                    </div>
                </div>
                                
                
            </div>
            <Drawer
                variant='temporary'
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleDrawerClose();
                    } else if (reason !== 'escapeKeyDown') {
                        handleDrawerClose();
                    }
                }}
                anchor='left'
                open={open}
                className='drawer'
                disableScrollLock={true}
            >
                <div className='div-closebtn'>
                    <CloseIcon
                        onClick={handleDrawerClose}
                        onKeyDown={(e) => {
                            if (e.key === ' ' || e.key === 'Enter') {
                                e.preventDefault();
                                handleDrawerClose();
                            }
                        }}
                        style={ClosebtnIconStyle}
                        role='button'
                        tabIndex='0'
                        aria-label='Close'
                    />
                </div>
                <br />

                <div onClick={handleDrawerClose}>
                    <div className='navLink--container'>
                        <Fade left>
                            <NavLink
                                to='/'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div style={DrawerItemStyle}>
                                    <IoHomeSharp
                                        style={drawerIconStyle}
                                    />
                                    <span style={DrawerLinksStyle}>
                                        Home
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>
                        <Fade left>
                            <NavLink
                                to='/#about'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div style={DrawerItemStyle}>
                                    <FaUser style={drawerIconStyle} />
                                    <span style={DrawerLinksStyle}>
                                        About
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>
                        <Fade left>
                            <NavLink
                                to='/#about'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div style={DrawerItemStyle}>
                                    <FaPeopleGroup style={drawerIconStyle} />
                                    <span style={DrawerLinksStyle}>
                                        Clients
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink
                                to='/#services'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div style={DrawerItemStyle}>
                                    <BsFillGearFill
                                        style={drawerIconStyle}
                                    />
                                    <span style={DrawerLinksStyle}>
                                        Services
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink
                                to='/#contacts'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div style={DrawerItemStyle}>
                                    <MdPhone style={drawerIconStyle} />
                                    <span style={DrawerLinksStyle}>
                                        Contact
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default Navbar;
