import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { Main} from './pages'
import { BackToTop } from './components'

import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
