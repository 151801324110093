export const serviceData = {
    bio : "Discover our specialized IT security training courses, designed to enhance your team's skills and protect your organization against growing digital threats. From advanced programs in cryptography and risk management to hands-on training in designing secure network infrastructures, our sessions are led by Mohamed Louati, a recognized PRESALES NETWORK SECURITY ENGINEER expert. Each course is carefully designed to provide in-depth knowledge and practical skills, reinforced by real-life case studies and interactive exercises. Invest in your company's security today.",
    services : [
        {
            id : 1,
            title : 'Mastering IT Security: Advanced Strategies',
            details : `This intensive training course immerses participants in advanced IT security concepts.`,
            date : 'Face-to-face / Remote',
            field : 'À partir de 1500€/participant',
            image : 'https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80'
        },
        {
            id : 2,
            title : 'AWS Certification',
            details : 'Validate technical client and cloud expertise to grow your career and business.',
            date : 'Face-to-face / Remote',
            field : 'À partir de 2000€/participant',
            image : 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const serviceData = {
    bio : "",
    services : []
}

*/