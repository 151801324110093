import React from 'react'
import { Helmet } from 'react-helmet'

import { Navbar, Footer, Landing, Client, Contacts, Service, About } from '../../components'


function Main() {
    return (
        <div>
            <Helmet>
                <title>TechnoFSK</title>
            </Helmet>

            <Navbar />        
            <Landing />
            <About/>
            <Client />
            <Service />
            <Contacts />
            <Footer />
        </div>
    )
}

export default Main
