
import ministere_armee from '../assets/svg/client/Ministère_des_Armées.svg'
import ministre_affaire from '../assets/svg/client/Ministère_des_Affaires_étrangères.svg'
import ministre_interieur from '../assets/svg/client/Ministère_Intérieur.svg'
import Atos from '../assets/svg/client/Atos.svg'
import IBM from '../assets/svg/client/IBM.svg'
import Sii from '../assets/svg/client/SII.svg'

export const clientImage = (client) => {
    const clientID = client.toLowerCase();
    switch (clientID) {
        case 'ministère des armées':
            return ministere_armee;
        case 'ministère des affaires étrangères':
            return ministre_affaire;
        case `ministère de l'intérieur`:
            return ministre_interieur;
        case `atos`:
            return Atos;
        case `ibm`:
            return IBM;
        case `sii`:
            return Sii;
        default:
            break;
    }
}
