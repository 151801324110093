import React, { useContext } from 'react';
import { Fade } from "react-awesome-reveal";

import { ThemeContext } from '../../contexts/ThemeContext';

import { FaEuroSign } from "react-icons/fa";

import './Service.css'

function AchievementCard({id, title, details, date, field, image}) {

    const { theme } = useContext(ThemeContext);

    const achievementCardStyle = {
        backgroundColor:theme.primary30,
        "&:hover": {
            backgroundColor:theme.primary50,
        },
    }

    
    return (
        <Fade bottom>
           <div key={id} className={`service-card ${achievementCardStyle}`} style={achievementCardStyle}>
               <div className="achievecard-content">
                    <div className="achievecard-details1">
                        <h2 style={{color: theme.tertiary}}>{title}</h2>
                        <p style={{color: theme.tertiary80}}>{details}</p>
                    </div>
                    <div className="achievecard-details2" style={{color: theme.primary}}>
                        <h5>{date}</h5>
                        {/* <div className="achievecard-field">
                            <FaEuroSign />
                            <h5>{field}</h5>
                        </div>    */}
                    </div>
                </div> 
                <div className="achievecard-imgcontainer">
                    <img src={image} alt="" />
                </div>
           </div>
        </Fade>
        
    )
}

export default AchievementCard
